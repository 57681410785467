import editorService from '@/services/ide/languages/html/editor.service'
import projectTreeService from '@/services/ide/projectTree.service'
import projectsService from '@/services/ide/projects.service'
import historyService from '@/services/ide/settings/history.service'
import { useIdeStore } from '@/stores/ide.store'

/**
 * Wait for the sync to finish
 * @param count - the count
 */
const multifileWaitForSync = async (count: number = 0) => {
  if (!projectTreeService.isSyncSuccess) {
    if (count > 8) {
      useIdeStore().setisCodeExecuting(false)
    } else {
      await new Promise((resolve) => setTimeout(resolve, 1000))
      multifileWaitForSync(count + 1)
    }
  }
}
/**
 * Execute the code
 * @param autoSave if true, auto save the project
 */
const execute = async (autoSave: boolean = true) => {
  // for advaced we dont need output script
  if (!useIdeStore().isAdvanced) {
    const script = editorService.getOutputScript()

    const textArea = document.getElementById('outputTextArea') as HTMLTextAreaElement
    textArea.value = script
  }
  if (useIdeStore().isAdvanced) {
    projectTreeService.syncBeforeExecute()
    await new Promise((resolve) => setTimeout(resolve, 1000))
    multifileWaitForSync()
  }
  const form = document.getElementById('outputForm') as HTMLFormElement
  form.setAttribute('target', 'output')
  form.submit()

  useIdeStore().setisCodeExecuting(false)
  if (autoSave) projectsService.autoSave()
}
/**
 * Try to execute the code
 */
const tryExecute = () => {
  if (useIdeStore().isCodeExecuting) return
  useIdeStore().setisCodeExecuting(true)
  execute()
  if (useIdeStore().isAdvanced) {
    historyService.addToRecent()
  } else {
    historyService.addToRecentHtml()
  }
}
/**
 * stop execute the code
 */
const stopExecution = () => {
  useIdeStore().setisCodeExecuting(false)
}

export default { tryExecute, execute, stopExecution }
