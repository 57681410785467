import { useIdeStore, type THtmlExecutionHistory } from '@/stores/ide.store'
import { usePluginStore } from '@/stores/plugin.store'
import { useProjectManager } from '@/stores/projectManager.store'
import { IDECONSTANT } from '@/utils/ide'
/**
 * set the font size of the editor
 * @param size - the size to set
 */
const setFontSize = (size?: number) => {
  if (size) {
    localStorage.setItem(IDECONSTANT.LOCALSTORAGE_FONT_SIZE_NAME, size.toString())
    useIdeStore().setFontSize(size)
  } else {
    if (localStorage.getItem(IDECONSTANT.LOCALSTORAGE_FONT_SIZE_NAME)) {
      useIdeStore().setFontSize(
        Number(localStorage.getItem(IDECONSTANT.LOCALSTORAGE_FONT_SIZE_NAME))
      )
    }
  }
  if (useIdeStore().fontSize) {
    if (useIdeStore().htmlDoctypeEditor) {
      useIdeStore().htmlDoctypeEditor.setFontSize(`${useIdeStore().fontSize}px`)
    }
    if (useIdeStore().htmlHeadEditor) {
      useIdeStore().htmlHeadEditor.setFontSize(`${useIdeStore().fontSize}px`)
    }
    if (useIdeStore().htmlBodyEditor) {
      useIdeStore().htmlBodyEditor.setFontSize(`${useIdeStore().fontSize}px`)
    }
    if (useIdeStore().htmlJsEditor) {
      useIdeStore().htmlJsEditor.setFontSize(`${useIdeStore().fontSize}px`)
    }
    if (useIdeStore().htmlCssEditor) {
      useIdeStore().htmlCssEditor.setFontSize(`${useIdeStore().fontSize}px`)
    }
    resizeCodeEditor()
  }
}
/**
 * get editor
 * @param key - the key of the editor
 * @returns the editor
 */
const getEditor = (key: (typeof IDECONSTANT)[keyof typeof IDECONSTANT]) => {
  return window.ace.edit(key)
}
/**
 * get the editor session
 * @param key - the key of the editor
 * @returns the editor session
 */
const getEditorSession = (key: (typeof IDECONSTANT)[keyof typeof IDECONSTANT]) => {
  return getEditor(key).getSession()
}
/**
 * set the editor value
 * @param key - the key of the editor
 * @param value - the value to set
 */
const setEditorSession = (key: (typeof IDECONSTANT)[keyof typeof IDECONSTANT], value: string) => {
  getEditor(key).getSession().setValue(value)
  heightChangeFunction(key)
}
/**
 * reset the code editor
 */
const resetCodeEditor = () => {
  const htmlDoctypeEditor = document.querySelector(`#${IDECONSTANT.HTML_DOC_TYPE_EDITOR}`)
  const htmlHeadEditor = document.querySelector(`#${IDECONSTANT.HTML_HEAD_EDITOR}`)
  const htmlBodyEditor = document.querySelector(`#${IDECONSTANT.HTML_BODY_EDITOR}`)
  const htmlJsEditor = document.querySelector(`#${IDECONSTANT.HTML_JS_EDITOR}`)
  const htmlCssEditor = document.querySelector(`#${IDECONSTANT.HTML_CSS_EDITOR}`)

  if (
    window.ace &&
    htmlDoctypeEditor &&
    htmlHeadEditor &&
    htmlBodyEditor &&
    htmlJsEditor &&
    htmlCssEditor
  ) {
    setEditorSession(IDECONSTANT.HTML_DOC_TYPE_EDITOR, '')
    setEditorSession(IDECONSTANT.HTML_HEAD_EDITOR, '')
    setEditorSession(IDECONSTANT.HTML_BODY_EDITOR, '')
    setEditorSession(IDECONSTANT.HTML_JS_EDITOR, '')
    setEditorSession(IDECONSTANT.HTML_CSS_EDITOR, '')
    useIdeStore().setCodeUpdated(true)
  }
}
/**
 * set the project
 * @param project - the project to set
 */
const setProject = (project: THtmlExecutionHistory) => {
  setEditorSession(IDECONSTANT.HTML_DOC_TYPE_EDITOR, project.docType)
  setEditorSession(IDECONSTANT.HTML_HEAD_EDITOR, project.htmlHead)
  setEditorSession(IDECONSTANT.HTML_BODY_EDITOR, project.htmlBody)
  setEditorSession(IDECONSTANT.HTML_JS_EDITOR, project.jsCode)
  setEditorSession(IDECONSTANT.HTML_CSS_EDITOR, project.cssCode)
}
/**
 * get the project
 * @returns the project
 */
const getProject = () => {
  return {
    docType: getEditorSession(IDECONSTANT.HTML_DOC_TYPE_EDITOR).getValue(),
    htmlHead: getEditorSession(IDECONSTANT.HTML_HEAD_EDITOR).getValue(),
    htmlBody: getEditorSession(IDECONSTANT.HTML_BODY_EDITOR).getValue(),
    jsCode: getEditorSession(IDECONSTANT.HTML_JS_EDITOR).getValue(),
    cssCode: getEditorSession(IDECONSTANT.HTML_CSS_EDITOR).getValue()
  }
}
/**
 * get the last execution
 * @returns the last execution
 */
const getLastExecution = (): THtmlExecutionHistory => {
  const project = getProject()
  const lastExecution: THtmlExecutionHistory = {
    ...project,
    executedAt: new Date().getTime()
  }
  return lastExecution
}
/**
 * convert to html string
 * @param htmlDoctype - the html doctype
 * @param htmlHead - the html head
 * @param htmlBody - the html body
 * @param htmlJs - the html js
 * @param htmlCss - the html css
 * @returns the html string
 */
const convertToHtmlString = (
  htmlDoctype: string,
  htmlHead: string,
  htmlBody: string,
  htmlJs: string,
  htmlCss: string
) => {
  const script = `
    ${htmlDoctype}
    <html>
        <head>
            <meta http-equiv="content-type" content="text/html; charset=UTF-8">
            <meta name="robots" content="noindex, nofollow">
            <meta name="googlebot" content="noindex, nofollow">
            ${htmlHead}
            <script type="text/javascript" async="true">
                ${htmlJs}
            </script>
            <style type="text/css">
                ${htmlCss}
            </style>
        </head>
        <body>
            ${htmlBody}
        </body>
    </html>`
  return script
}
/**
 * get the output script
 * @returns the output script
 */
const getOutputScript = () => {
  const htmlDoctype = getEditorSession(IDECONSTANT.HTML_DOC_TYPE_EDITOR).getValue()
  const htmlHead = getEditorSession(IDECONSTANT.HTML_HEAD_EDITOR).getValue()
  const htmlBody = getEditorSession(IDECONSTANT.HTML_BODY_EDITOR).getValue()
  const htmlJs = getEditorSession(IDECONSTANT.HTML_JS_EDITOR).getValue()
  const htmlCss = getEditorSession(IDECONSTANT.HTML_CSS_EDITOR).getValue()

  return convertToHtmlString(htmlDoctype, htmlHead, htmlBody, htmlJs, htmlCss)
}
/**
 * set code editor sample script
 * @param key - the key of the editor
 * @param script - the script to set
 */
const codeEditorSetSampleScript = (
  key: (typeof IDECONSTANT)[keyof typeof IDECONSTANT],
  script: string
) => {
  setEditorSession(key, script)
}
/**
 * Set code editors theme
 */
const codeEditorsSetTheme = () => {
  const isDark =
    usePluginStore().isPlugin || useIdeStore().isEmbedded
      ? false
      : localStorage.getItem('jdoodle-mode') == 'dark'
  if (isDark) {
    if (useIdeStore().htmlDoctypeEditor) {
      useIdeStore().htmlDoctypeEditor.setTheme('ace/theme/gruvbox')
    }
    if (useIdeStore().htmlHeadEditor) {
      useIdeStore().htmlHeadEditor.setTheme('ace/theme/gruvbox')
    }
    if (useIdeStore().htmlBodyEditor) {
      useIdeStore().htmlBodyEditor.setTheme('ace/theme/gruvbox')
    }
    if (useIdeStore().htmlJsEditor) {
      useIdeStore().htmlJsEditor.setTheme('ace/theme/gruvbox')
    }
    if (useIdeStore().htmlCssEditor) {
      useIdeStore().htmlCssEditor.setTheme('ace/theme/gruvbox')
    }
  } else {
    if (useIdeStore().htmlDoctypeEditor) {
      useIdeStore().htmlDoctypeEditor.setTheme('ace/theme/xcode')
    }
    if (useIdeStore().htmlHeadEditor) {
      useIdeStore().htmlHeadEditor.setTheme('ace/theme/xcode')
    }
    if (useIdeStore().htmlBodyEditor) {
      useIdeStore().htmlBodyEditor.setTheme('ace/theme/xcode')
    }
    if (useIdeStore().htmlJsEditor) {
      useIdeStore().htmlJsEditor.setTheme('ace/theme/xcode')
    }
    if (useIdeStore().htmlCssEditor) {
      useIdeStore().htmlCssEditor.setTheme('ace/theme/xcode')
    }
  }
  if (useIdeStore().ideSettings?.theme)
    useIdeStore().codeEditor.setTheme(`ace/theme/${useIdeStore().ideSettings?.theme}`)
}
/**
 * resize when the window is resized or fullscreen is toggled or keyup
 */
const resizeCodeEditor = () => {
  heightChangeFunction(IDECONSTANT.HTML_DOC_TYPE_EDITOR)
  heightChangeFunction(IDECONSTANT.HTML_HEAD_EDITOR)
  heightChangeFunction(IDECONSTANT.HTML_BODY_EDITOR)
  heightChangeFunction(IDECONSTANT.HTML_JS_EDITOR)
  heightChangeFunction(IDECONSTANT.HTML_CSS_EDITOR)
}
/**
 * update the editor height
 * @param key - the key of the editor
 */
const heightChangeFunction = (key: (typeof IDECONSTANT)[keyof typeof IDECONSTANT]) => {
  const editor = getEditor(key)

  const newHeight =
    editor.getSession().getScreenLength() * editor.renderer.lineHeight +
    editor.renderer.scrollBar.getWidth()
  const minHeight = 15

  document.getElementById(key)?.style.setProperty('height', `${newHeight}px`)
  document.getElementById(key)?.style.setProperty('min-height', `${minHeight} px`)
  editor.resize()
}
/**
 * On change HtmlDoctype editor
 * @param id - The id of the ace editor
 */
const onChangeOutputEditor = (id: (typeof IDECONSTANT)[keyof typeof IDECONSTANT]) => {
  getEditorSession(id).on('change', () => {
    heightChangeFunction(id)
    useIdeStore().setCodeUpdated(true)
  })
}
/**
 * Initialize the ace editor
 * @param id - The id of the ace editor to initialize
 * @param language - The language of the ace editor
 * @returns The ace editor
 */
const initAceEditor = (id: (typeof IDECONSTANT)[keyof typeof IDECONSTANT], language: string) => {
  window.ace.require('ace/ext/language_tools')
  window.ace.config.set('basePath', '/assets/javascript/ace')

  if (window?.ace?.edit(id)) {
    window?.ace?.edit(id).destroy()
  }
  const editor: ace['Editor'] = window.ace.edit(id)
  editor.getSession().setMode(`ace/mode/${language}`)

  editor.renderer.setShowGutter(false)
  editor.renderer.setAnimatedScroll(true)
  editor.renderer.setShowPrintMargin(false)

  editor.setOptions({
    enableBasicAutocompletion: true,
    enableSnippets: true,
    enableLiveAutocompletion: false
  })
  editor.$blockScrolling = Infinity
  if (useProjectManager().projectPermissionWithId?.readOnly) editor.setReadOnly(true)

  onChangeOutputEditor(id)
  editor.$highlightActiveLine = false
  editor.getSession().setWrapLimit(80)
  editor.getSession().setUseWrapMode(true)

  return editor
}

/**
 * Initialize the editor when router changes
 * @param count - The count of the init
 * @returns null
 */
const initEditors = async (count: number = 0) => {
  if (!useIdeStore().isWindowAce()) {
    if (count > 50) {
      return null
    } else {
      await new Promise((resolve) => setTimeout(resolve, 100))
      initEditors(count + 1)
    }
  } else {
    if (!useIdeStore().htmlDoctypeEditor) {
      useIdeStore().htmlDoctypeEditor = initAceEditor(IDECONSTANT.HTML_DOC_TYPE_EDITOR, 'html')
      codeEditorSetSampleScript(IDECONSTANT.HTML_DOC_TYPE_EDITOR, '<!DOCTYPE html>')
    }
    if (!useIdeStore().htmlHeadEditor) {
      useIdeStore().htmlHeadEditor = initAceEditor(IDECONSTANT.HTML_HEAD_EDITOR, 'html')
      codeEditorSetSampleScript(
        IDECONSTANT.HTML_HEAD_EDITOR,
        `<script type="text/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/jquery/2.2.1/jquery.min.js"> </script>`
      )
    }
    if (!useIdeStore().htmlBodyEditor) {
      useIdeStore().htmlBodyEditor = initAceEditor(IDECONSTANT.HTML_BODY_EDITOR, 'html')
      codeEditorSetSampleScript(
        IDECONSTANT.HTML_BODY_EDITOR,
        `<div class="welcome">Welcome To JDoodle.com</div>`
      )
    }
    if (!useIdeStore().htmlJsEditor) {
      useIdeStore().htmlJsEditor = initAceEditor(IDECONSTANT.HTML_JS_EDITOR, 'javascript')
      codeEditorSetSampleScript(
        IDECONSTANT.HTML_JS_EDITOR,
        `$(document).ready(function() { $(".welcome").append("!!!");}); `
      )
    }
    if (!useIdeStore().htmlCssEditor) {
      useIdeStore().htmlCssEditor = initAceEditor(IDECONSTANT.HTML_CSS_EDITOR, 'css')
      codeEditorSetSampleScript(
        IDECONSTANT.HTML_CSS_EDITOR,
        `.welcome {
        color:green;
        font-size:36px;
        font-family:cursive;
        text-align:center;
        padding:20px;
    }`
      )
    }
    codeEditorsSetTheme()
    setFontSize()
    await new Promise((resolve) => setTimeout(resolve, 100))
    resizeCodeEditor()
    useIdeStore().setCodeUpdated(false)
  }
}
export default {
  initEditors,
  codeEditorsSetTheme,
  resizeCodeEditor,
  getEditorSession,
  getOutputScript,
  getLastExecution,
  convertToHtmlString,
  resetCodeEditor,
  setProject,
  getProject,
  setFontSize
}
