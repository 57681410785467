import { useAuthStore } from '@/stores/auth.store'
import posthog from 'posthog-js'
import { watch } from 'vue'

const PROJECTAPIKEY = 'phc_DBkbsPSYbOmheeCje3FIkcLaJFLMByt6TCeiBbbssz6'
const CLIENTAPIHOST = 'https://eu.i.posthog.com'

let appInitiated: boolean = false

const posthogPlugin = {
  install(app: any) {
    app.config.globalProperties.$posthog = posthog.init(PROJECTAPIKEY, {
      api_host: CLIENTAPIHOST,
      person_profiles: 'identified_only'
    })
  }
}

/**
 * this function initializes the posthog watcher
 * @param app - the app instance
 */
const initPostHogWatcher = (app: any) => {
  const appInstant = app
  watch(
    () => useAuthStore().isSoftwareDeveloper,
    (value) => {
      if (value) {
        const environment = useAuthStore().environment
        if ((!appInitiated && environment !== 'development') || environment !== 'test') {
          appInstant.use(posthogPlugin)
          appInitiated = true
        }
      } else resetPostHog()
    }
  )
}
/**
 * this function captures the event
 * @param eventName - name of the event
 * @param data - data to be sent with the event
 */
const captureEvent = (eventName: string, data: any) => {
  if (!useAuthStore().isSoftwareDeveloper) return
  try {
    const dataWithEmail = { ...data, email: useAuthStore().userEmail }
    posthog.capture(eventName, { ...dataWithEmail })
  } catch (error) {
    ;() => {}
  }
}

/**
 * resets the posthog instance when the user logs out
 */
const resetPostHog = () => {
  posthog.reset(true)
}

export default { initPostHogWatcher, captureEvent }
