//Model
// ide

export const IDETABSVIEW = {
  IDE: 'ideview',
  ADVANCEIDE: 'advanceide',
  JDROID: 'jdroid',
  JDROIDCHAT: 'jdroidchat'
}

export const IDEVIEWSPLITTABS = {
  INPUT: 'splitInputComp',
  OUTPUT: 'splitOutputComp',
  EXTERNALLIBRARIES: 'splitExternalLibrariesComp',
  LIBRARYMANAGER: 'splitLibraryManagerComp',
  INPUTFILESCOMP: 'splitInputFilesComp',
  UPLOADFILESCOMP: 'splitUploadFilesComp'
}

export const IDEVIEWSPLITTABSCOLLAPSEPOSITION = {
  LEFT: 'Left',
  RIGHT: 'Right'
}

export const IDEVIEWTABS = {
  INPUTFILES: 'tabs-input-files',
  LIBRARIES: 'tabs-libs',
  INPUT: 'tabs-input',
  OUTPUT: 'tabs-output',
  IO: 'tabs-io',
  JDROID: 'tabs-jdroid'
}

export const IDEVIEWTABS_MOBILE = {
  JDROID: 'tabs-jdroid-mobile',
  IDE: 'tabs-ide-mobile',
  INPUTFILES: 'tabs-input-files-mobile',
  LIBRARIES: 'tabs-libs-mobile',
  INPUT: 'tabs-input-mobile',
  OUTPUT: 'tabs-output-mobile',
  SOCIALSHARE: 'tabs-social-mobile'
}

export const IDEASSESSMENTTABS = {
  TRYSECTION: 'trysection-tabs'
}

//Model
//user profile
export const USERPROFILETABS = {
  BASIC: 'tabs-basic',
  PASSWORD: 'tabs-password'
}

// Pricing tabs
export enum PRICING_TAB_ENUM {
  INDIVIDUAL = 'Individual Plan',
  ORGANIZATION = 'Organisation Plan'
}
