import editorService from '@/services/ide/editor.service'
import blocklyService from '@/services/ide/languages/blockly/blockly.service'
import htmlEditorService from '@/services/ide/languages/html/editor.service'
import projectsService from '@/services/ide/projects.service'
import utilModelsService from '@/services/util.models.service'
import { useAuthStore } from '@/stores/auth.store'
import { useIdeStore } from '@/stores/ide.store'
import { IDECONSTANT } from '@/utils/ide'
import { AUTHMODELHS, IDEVIEWMODELS } from '@/utils/models'
import * as all from 'file-saver'
/**
 * close the popup
 * @param hs - The hs of the popup
 */
const closePopup = (hs: (typeof IDEVIEWMODELS)[keyof typeof IDEVIEWMODELS] | null = null) => {
  if (useIdeStore().isSettingPopupHs === hs || hs === null) {
    useIdeStore().setSettingPopupHs(null)
  }
}
/**
 * open the popup
 * @param hs - The hs of the popup
 * @param needLogin - if the user need to login
 */
const openPopup = (hs: (typeof IDEVIEWMODELS)[keyof typeof IDEVIEWMODELS], needLogin = false) => {
  if (needLogin && !useAuthStore().isUserloggedIn) {
    utilModelsService.openModal(AUTHMODELHS.LOGIN)
    useIdeStore().setSettingPopupHs(null)
  } else {
    if (useIdeStore().isSettingPopupHs === hs) {
      useIdeStore().setSettingPopupHs(null)
    } else {
      useIdeStore().setSettingPopupHs(hs)
    }
  }
}
/**
 * Print the page
 */
const print = async () => {
  editorService.resizeCodeEditor()
  editorService.resizeOutputEditor()
  await blocklyService.resizeBlockly()
  await new Promise((resolve) => setTimeout(resolve, 250))
  window.print()
  useIdeStore().setPrintBlocker(false)
  await new Promise((resolve) => setTimeout(resolve, 10))
  editorService.resizeCodeEditor()
  editorService.resizeOutputEditor()
  await blocklyService.resizeBlockly()
}
/**
 * Pretty print the page
 */
const prettyPrint = async () => {
  useIdeStore().setPrintBlocker(true)
  if (useIdeStore().isFullScreen) {
    useIdeStore().setFullScreen(false)
    await new Promise((resolve) => setTimeout(resolve, 250))
    await print()
    useIdeStore().setFullScreen(true)
  } else {
    await print()
  }
}
/**
 * open the file from import
 */
const openFromImport = () => {
  const script = editorService.getEditorSession(IDECONSTANT.OPEN_FROM_FILE_EDITOR).getValue()
  editorService.setEditorSession(IDECONSTANT.CODE_EDITOR, script)
  useIdeStore().setCodeUpdated(true)
}
/**
 *  Try upload file
 * @param event - The event
 * @returns the file content
 */
const importFromFile = async (event: Event) => {
  return new Promise((resolve, reject) => {
    try {
      const inputElement = event.target as HTMLInputElement
      if (inputElement?.files && inputElement.files[0]) {
        const file = inputElement.files[0]
        if (!file) {
          reject(new Error('Unable to import the file. Please try again.'))
          return
        }
        const reader = new FileReader()
        /**
         * read the file
         * @param e - The event
         */
        reader.onload = (e: ProgressEvent<FileReader>) => {
          if (!e.target || !e.target.result) {
            reject(new Error('File is empty. Please try again.'))
            return
          }
          if (e.target.result === '') {
            reject(new Error('File is empty. Please try again.'))
            return
          }
          editorService.setEditorSession(IDECONSTANT.CODE_EDITOR, e.target.result as string)
          resolve(e.target.result)
        }
        reader.readAsText(file)
      }
    } catch (e) {
      reject(e)
    }
  })
}
/**
 * download the current code text
 * @param id - The id of the editor
 * @returns true if success else error
 */
const download = async (id: (typeof IDECONSTANT)[keyof typeof IDECONSTANT]) => {
  return new Promise((resolve, reject) => {
    try {
      let fileName: string | null = null
      let code: string | null = null
      if (useIdeStore().isHtml) {
        fileName = 'jdoodle.html'
        code = htmlEditorService.getOutputScript() as string
      } else {
        code = editorService.getEditorSession(id).getValue()
        fileName = 'jdoodle.' + useIdeStore().ideMeta?.fileName
        if (useIdeStore().isLanguage === 'java') {
          // eslint-disable-next-line no-useless-escape
          const regex = /(public)([\s]*)(class)([\s]*)([\w\$]*)([\s]*)({)/
          const match = regex.exec(code as string)
          if (match && match.length >= 5) {
            fileName = match[5] + '.java'
          } else {
            fileName = 'jdoodle.java'
          }
        }
      }
      const file = new File([code as string], fileName as string, {
        type: 'text/plain;charset=utf-8'
      })
      all.saveAs(file)
      resolve(true)
    } catch (e) {
      reject(e)
    }
  })
}
/**
 * copy the current code text to clipboard
 * @returns true if success else false
 */
const copyToClipboard = async () => {
  const text = editorService.getEditorSession(IDECONSTANT.CODE_EDITOR).getValue()
  return window.navigator.clipboard.writeText(text).then(
    () => {
      return true
    },
    () => {
      return false
    }
  )
}
/**
 * init the copy editor
 */
const initCopyEditor = () => {
  if (!useIdeStore().copyEditor && useIdeStore().codeEditor) {
    useIdeStore().copyEditor = editorService.initAceEditor(IDECONSTANT.COPY_CODE_EDITOR)
    useIdeStore().copyEditor.renderer.setShowGutter(true)

    editorService.codeEditorsSetTheme()
  }
}
/**
 * refresh the copy editor every time the model is open
 */
const refreshCopyEditor = () => {
  initCopyEditor()
  if (useIdeStore().codeEditor && useIdeStore().copyEditor) {
    const text = editorService.getEditorSession(IDECONSTANT.CODE_EDITOR).getValue()
    editorService.setEditorSession(IDECONSTANT.COPY_CODE_EDITOR, text)
  }
}
/**
 * init the download editor
 */
const initDownloadEditor = () => {
  if (
    (!useIdeStore().codeEditor && !useIdeStore().htmlDoctypeEditor) ||
    useIdeStore().downloadEditor
  )
    return
  useIdeStore().downloadEditor = editorService.initAceEditor(IDECONSTANT.DOWNLOAD_CODE_EDITOR)
  useIdeStore().downloadEditor.renderer.setShowGutter(true)

  editorService.codeEditorsSetTheme()
}
/**
 * refresh the download editor every time the model is open
 */
const refreshDownloadEditor = () => {
  initDownloadEditor()
  if (
    (!useIdeStore().codeEditor && !useIdeStore().htmlDoctypeEditor) ||
    !useIdeStore().downloadEditor
  )
    return
  let text: string | null = null
  if (useIdeStore().isHtml) {
    text = htmlEditorService.getOutputScript() as string

    editorService.setEditorSession(IDECONSTANT.DOWNLOAD_CODE_EDITOR, text)
  } else {
    text = editorService.getEditorSession(IDECONSTANT.CODE_EDITOR).getValue() as string
    editorService.setEditorSession(IDECONSTANT.DOWNLOAD_CODE_EDITOR, text)
  }
}
/**
 * init the open editor
 */
const initOpenEditor = () => {
  if (!useIdeStore().openEditor && useIdeStore().codeEditor) {
    useIdeStore().openEditor = editorService.initAceEditor(IDECONSTANT.OPEN_FROM_FILE_EDITOR)
    useIdeStore().openEditor.renderer.setShowGutter(true)
    editorService.getEditorSession(IDECONSTANT.OPEN_FROM_FILE_EDITOR).setMode('ace/mode/text')

    editorService.codeEditorsSetTheme()
  }
}
/**
 * refresh the open editor every time the model is open
 */
const refreshOpenEditor = () => {
  useIdeStore().setImportedFromFiles(false)
  initOpenEditor()
}

/**
 * clear the project
 */
const clearProject = () => {
  projectsService.clearProject()
  if (useIdeStore().isBlockly) {
    const sampleScript = useIdeStore().routeMeta?.sampleScript
    blocklyService.setSampleScript(sampleScript as string)
  }
  useIdeStore().setCodeUpdated(true)
  // clear open from file input
  const inputElement = document.getElementById('openFromFileInput') as HTMLInputElement
  if (inputElement) {
    inputElement.value = ''
  }
}
/**
 * add java libraries
 * @param lib - the library to add
 */
const addlibraries = (lib: string) => {
  if (useIdeStore().libraries.indexOf(lib) < 0) {
    useIdeStore().libraries.push(lib)
    useIdeStore().setCodeUpdated(true)
  }
}
/**
 * remove java libraries
 * @param lib - the library to remove
 */
const removelibraries = (lib: string) => {
  const index = useIdeStore().libraries.indexOf(lib)
  if (index >= 0) {
    useIdeStore().libraries.splice(index, 1)
    useIdeStore().setCodeUpdated(true)
  }
}
export default {
  closePopup,
  openPopup,
  openFromImport,
  importFromFile,
  download,
  copyToClipboard,
  initCopyEditor,
  refreshCopyEditor,
  initDownloadEditor,
  refreshDownloadEditor,
  initOpenEditor,
  refreshOpenEditor,
  clearProject,
  addlibraries,
  removelibraries,
  prettyPrint
}
