/* eslint-disable jsdoc/require-jsdoc */
import { META, setDefaultPath, type IMeta } from '@/utils/meta'
import { cloneDeep } from 'lodash-es'
import { type RouteRecordRaw } from 'vue-router'

const ideRoutes: RouteRecordRaw[] = []
const paths: string[] = []

for (const key in META) {
  const lang: IMeta = META[key]

  if (typeof lang.isCompile === 'boolean' && lang.langDisplayName) {
    const canonicalPath = `/${lang.canonicalPath}`
    paths.push(canonicalPath)

    // Wasn't able to specify record for HTML in router/index.ts
    // since both initialized with different IDEs
    // To be explored, override of an argument in component field
    if (key === 'html') {
      const ideRoute: RouteRecordRaw = {
        path: canonicalPath,
        name: lang.langDisplayName,
        component: () => import('@/views/HTMLIDE.vue'),
        meta: lang,
        beforeEnter: [setDefaultPath]
      }
      ideRoutes.push(ideRoute)

      if (lang.isPlus) {
        const langPlus = cloneDeep(lang)
        langPlus.isAdvanced = true
        langPlus.basicPath = langPlus.canonicalPath
        langPlus.canonicalPath = `${langPlus.canonicalPath}-ide`
        const advancedCanonicalPath = `/${langPlus.canonicalPath}`
        paths.push(advancedCanonicalPath)

        const advancedIdeRoute: RouteRecordRaw = {
          path: advancedCanonicalPath,
          name: `${langPlus.langDisplayName} Advanced IDE`,
          component: () => import('@/views/HTMLIDE.vue'),
          meta: langPlus,
          beforeEnter: [setDefaultPath]
        }

        ideRoutes.push(advancedIdeRoute)
      }
    } else {
      const ideRoute: RouteRecordRaw = {
        path: canonicalPath,
        name: lang.langDisplayName,
        component: () => import('@/views/IDE.vue'),
        meta: lang,
        beforeEnter: [setDefaultPath]
      }
      ideRoutes.push(ideRoute)

      if (lang.isPlus) {
        const langPlus = cloneDeep(lang)
        langPlus.isAdvanced = true
        langPlus.basicPath = langPlus.canonicalPath
        langPlus.canonicalPath = `${langPlus.canonicalPath}-ide`
        const advancedCanonicalPath = `/${langPlus.canonicalPath}`
        paths.push(advancedCanonicalPath)

        const advancedIdeRoute: RouteRecordRaw = {
          path: advancedCanonicalPath,
          name: `${langPlus.langDisplayName} Advanced IDE`,
          component: () => import('@/views/IDE.vue'),
          meta: langPlus,
          beforeEnter: [setDefaultPath]
        }

        ideRoutes.push(advancedIdeRoute)
      }
    }
  }
}

export default ideRoutes
